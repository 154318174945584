.finder .left-container{
    position: absolute;
    top: $search-box-margin;
    left: $search-box-margin;
    z-index: 9800;
    pointer-events: none;
    height: calc(100% - #{$search-box-margin} * 2);
    width: $search-box-width;
    max-width: calc(100% - 5rem);
    opacity: 0;
    ul{
        margin: 0;
        padding: 0;
    }
    &.transitions{
        transition: left 0.5s, opacity 1.5s;
        opacity: 1;
    }
    @media screen and (max-width: $mobile-width) {
        left: 0;
    }
    .left-container_close-button{
        border: none;
        display: block;
        right: -3rem;
        top: 1rem;
        width: 3rem;
        height: 5rem;
        padding: 0;
        position: absolute;
        z-index: -1;
        pointer-events: auto;
        cursor: pointer;
        outline-offset: -.1rem;
        .icon{
            display: inline-block;
            fill: $light-gray;
            font-size: 1.25rem;
            text-align: center;
            font-weight: 300;
            width: 100%;
        }
        .closed-icon{
            display: none;
        }
        &:hover{
            .icon{
                fill: $dark-gray;
            }
            background-color: $primary-bg-color !important;
        }
    }
    &.closed .left-container_close-button{
        .closed-icon{
            display: inline-block;
        }
        .open-icon{
            display: none;
        }
    }
}
