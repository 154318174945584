
.unified-player {
	&, main{
		background: $secondary-bg-color;
	}
	.primary-container{
		background-color: $darkest-gray;
		flex-shrink: 0;
		&.no-content{
			position: absolute;
			top: 0; bottom: 0;
			left: 0; right: 0;
			display: flex;
			color: white;
			font-size: 1.6em;
			justify-content: center;
			align-items: center;
			text-align: center;
			.message-container{
				width: 100%;
			    display: flex;
			    justify-content: center;
			    height: 100%;
			}
			.center-container{
				&.center{
					display: flex;
				    align-items: center;
				    height: 100%;
				}
			}
			.message{
				padding: 2em 2em 1em 2em;
			}
			.message div{
				max-width: 29em;
			}
			.left-align{
				text-align: left;
			}
			a{
				color: $light-gray;
				&:hover{
					color: white;
					text-decoration: underline;
				}
			}
			.icon{
				margin: 1em 0;
				font-size: 2em;
			}
		}
	}
	main{
		display: flex;
		flex-direction: column;
	}
	.tab-nav{
		margin-top: .5rem;
		button{
			font-size: 1.4em;
			padding-bottom: .9rem;
			padding-top: .9rem;
		}
	}
	a.header-logo, .share-container{
		position: fixed;
		top: .5em;
		padding: .5em .65em;
		&:hover{
			color: $lightest-gray;
		}
		.full{
			display: none;
		}
		// use this instead if we want to show the full logo at larger widths
		// @include breakpointMin(480px){
		// 	.small{
		// 		display: none;
		// 	}
        // }
		// @include breakpointMax(480px){
        //     .full{
		// 		display: none;
		// 	}
        // }
	}
	a.header-logo{
		left: .5em;
		font-size: 1.25em;
		color: $dark-gray;
	}
	.player-overlay{
		z-index: 1000;
		&.share-modal{
			max-width: 34em;
		}
	}
	.share-container{
		cursor: pointer;
		right: .5em;
		font-size: 1.3em;
		color: $light-gray;
		.icon{
			margin-right: .5em;
		}
		@include breakpointMax(480px){
            span{
				display: none;
			}
			.icon{
				margin-right: 0;
			}
        }
	}
	.video-container, .audio-container{
		overflow: hidden;
	}
	.video-container{
		width: 100%;
		height: calc(100vh - 27rem);
		max-height: 60rem;
		transition: height $generic-transition-speed;
		@include breakpointMax(480px, true){
            height: 15rem;
        }
	}
	.video-player{
		padding-bottom: 0;
		height: 100%;
		.broadcaster-online-notification{
			display: none;
		}
	}
	.video-player.audio-mode:not(.unplayed){
		background-color: $black;
	}
	.audio-container{
		height: 13rem;
		transition: height $generic-transition-speed;
		padding: 0 1rem;
		.audio-player{
			margin-top: 2.5rem;
		}
		.sa_wave-container, .sa_wave-container .sa_wave_mask{
			height: 6rem;
		}
	}
	&.video-active{
		.audio-container{
			height: 0;
		}
	}
	&.audio-active:not(.webcast){
		.video-container{
			height: 0;
			min-height: 0;
		}
	}
	.title-container{
		color: white;
	}
	.series{
		color: $light-gray;
		margin-top: .5em;
		.series-title{
			text-transform: uppercase;
		}
		a{
			margin-left: .75em;
			font-style: italic;
			color: currentColor;
			&:hover{
				color: $lightest-gray;
			}
		}
	}
	.metadata-container{
		height: 100%;
	}
	.metadata-column{
		flex-direction: column;
	}
	.metadata-container, .title-container{
		font-size: 1.2em;
	}
	.title-container{
		padding: 1em;
		.title{
			font-size: 1.6em;
			font-weight: bolder;
		}
		a{
			color: white;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	@include title-switcher(540px);
	.metadata-row{
		display: flex;
		&:not(:first-child){
			font-size: 1.1em;
		}
	}
	.name-header{
		text-transform: uppercase;
	}
	.name-container{
		font-size: 1.1em;
	}
	.metadata-row{
		flex-wrap: wrap;
		padding: 1em;
		padding-bottom: 0;
		+ .metadata-row{
			border-top: $generic-border;
			padding-top: 1em;
		}
		a{
			color: $darkest-gray;
		}
		a:hover{
			color: black;
			text-decoration: underline;
		}
		.icon{
			margin-right: .5em;
		}
	}
	.viewer-count{
		margin-right: .5em;
		font-weight: bolder;
	}
	.stats-container{
		text-transform: uppercase;
	}
	.secondary-metadata{
		justify-content: space-between;
	}
	.main-metadata, .secondary-metadata{
		background: $primary-bg-color;
		> div{
			display: inline-flex;
			align-items: center;
			margin-bottom: 1em;
		}
	}
	.main-metadata > div{
		margin-bottom: 1.5em;
		&:not(:last-child){
			margin-right: 1.5em;
		}
		height: 4em;
		.speaker-img, .broadcaster-img{
			margin-right: 1em;
		}
		.speaker-img{
			width: 4em;
			height: 4em;
		}
		.broadcaster-img{
			width: 5em;
			img{
				width: 100%;
			}
		}
	}
	.broadcaster-display .name-container{
		margin-top: .9em;
	}
	.broadcaster-location{
		color: $gray;
		font-size: .85em;
	}
	.tabular-data{
		display: flex;
		.column{
			margin-right: 1.5em;
			color: $darkest-gray;
		}
		.labels{
			text-transform: uppercase;
			color: $gray;
		}
		.row:not(:last-child){
			margin-bottom: .5em;
		}
	}
}
