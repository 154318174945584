.search{
    .sermon-count{
        margin-top: 2.5rem;
        text-align: center;
    }
    .primary-content{
        @extend .splash;
        padding: 2rem 12rem 4rem;
        @media screen and (max-width: 720px){
            padding: 1rem 4rem 4rem;
        }
        @media screen and (max-width: $mobile-width){
            padding: 1rem 2rem 3rem;
        }
        h1{
            margin: 4rem 0 2rem;
            text-align: center;
            font-size: 1.8em;
        }
        .search-bar{
            width: 60rem;
            max-width: 100%;
            margin: 0 auto 1rem;
            font-size: 1.4em;
        }
    }
    .sermons{
        @extend .sermon-list;
        margin-top: 2rem;
        overflow: hidden;
        .sermon{
            border: none;
            padding: 1.25rem 1.75rem 0rem 1.75rem;
            &:hover{
                background-color: $secondary-bg-color;
            }
            .sk-wave{
                margin: 3rem auto;
            }
            a.title{
                font-size: 1.5em !important;
            }
            .buttons{
                padding-top: .2rem;
            }
            .info{
                padding-left: .5rem;
                @media screen and (max-width: $mobile-width){
                    padding-left: 0;
                }
            }
            .more-info{
                margin-top: .2rem !important;
                padding-left: .2rem;
                position: relative;
                left: -.2rem;
                height: 1.8rem !important;
            }
        }
    }
    .footer{
        @extend .sermon-list-pagination;
        display: flex;
        box-sizing: content-box;
        width: calc(100% - 3rem);
        button{
            align-self: initial;
        }
    }
}
