.sermon_page{

    $border-style: $generic-border;
    $one-column-padding: 2rem;
    $two-and-three-column-padding: 3rem;
    $three-column-large-padding: 4.5rem;
    $one-column-max: $modal-stack-width;
    $two-column-max: $desktop-small;
    $two-column-max-large: $desktop-medium;
    $sidebar-width: 35rem;
    $sidebar-width-small: $sidebar-width - 10rem;

     .media-section-header, .audio-container, .video-container{
        padding-left: 3em;
        padding-right: 3em;
    }

    .page-body{
      width: 100%;
      max-width: $desktop-large;
      overflow: auto;
      margin: 0 auto;
      @include breakpointMax($one-column-max) {
        flex-direction: column;
        display: flex;
      }
      > div{
        display: inline-block;
        float: left;
        &.sidebar{
            float: none;
        }
        // Three Columns
        @include breakpointMin($two-column-max + 1) {
          &.body-container, &.metadata-primary{
            width: calc(100% - (#{$sidebar-width-small} + #{$two-and-three-column-padding}) * 2);
            margin: 0 $two-and-three-column-padding;
          }
          &.body-container{
            min-height: 20rem;
          }
          &.source-container, &.sidebar{
            width: $sidebar-width-small;
            min-height: 20em;
          }
        }
        // Three Columns Large
        @include breakpointMin($two-column-max-large + 1) {
          &.body-container, &.metadata-primary{
              width: calc(100% - (#{$sidebar-width} + #{$two-and-three-column-padding}) * 2);
          }
          &.source-container, &.sidebar{
            width: $sidebar-width;
          }
        }
        // Two Columns
        @include breakpointMinMax($one-column-max + 1, $two-column-max) {
          &.body-container, &.metadata-primary, &.source-container{
              width: calc(100% - (#{$sidebar-width-small} + #{$two-and-three-column-padding} * 2));
              margin: 0 $two-and-three-column-padding;
              clear: left;
          }
          &.sidebar{
            width: $sidebar-width-small;
          }
        }
        // One column only
        @include breakpointMax($one-column-max) {
          width: 100%;
          &.source-container{ order: 1; }
          &.metadata-primary{ order: 2; }
          &.sidebar         { order: 3; }
          &.body-container  { order: 4; }
          &.metadata-primary, &.source-container, &.body-container{
              padding-left: $one-column-padding;
              padding-right: $one-column-padding;
          }
        }
      }
    }
    .player{
        background-color: $black;
        transition: background-color 1s;
        .audio-container, .video-container{
            padding-bottom: 2em;
        }
        .media-section-header{
            font-size: 1em;
            padding-top: .25em;
            @include breakpointMax($one-column-max){
                font-size: .8em;
            }
            h1{
                font-size: 3.2em;
                font-weight: 400;
                color: $lightest-gray;
                margin-bottom: 0;
                margin-top: 1em;
                @include title-switcher();
            }
            .series{
                color: $gray;
                font-size: 1.6em;
                margin: .5em 0;
                label{
                    font-weight: 600;
                    margin-right: 1em;
                }
                h2{
                    font-weight: 400;
                    display: inline-block;
                    font-style: italic;
                    margin: 0;
                }
            }
        }
        .video-container{
            padding-bottom: 1em;
        }
        &.audio .video-container, &.video .audio-container{
            display: none;
        }
        &.video{
            background-color: black;
        }
        .audio-container{
            margin: 1.5rem auto;
            .no-sermon, .noHTML5Message {
                font-size: 1.5rem;
                margin: 1rem auto 1rem;
                h2, &{
                    color: $light-gray;
                    font-weight: 300;
                    text-align: center;
                    a{
                        color: $primary-accent-color;
                        &:hover{
                            color: $primary-accent-color-highlight;
                        }
                    }
                }
            }
        }
        .video-player, .video-container, .jwplayer, .jw-aspect{
            height: auto !important;
        }
        .video-container, .video-player {
            margin: auto;
            box-sizing: content-box;
            padding-top: 0;
            max-width: calc((75vh * 1.7777) - 20rem);
        }
    }
    .metadata-primary{
        box-sizing: border-box;
        border-bottom: $border-style;
        .flexible-container{
            display: flex;
            flex-wrap: wrap;
            margin-top: 3rem;
            margin-bottom: 1.5rem;
            font-size: 1.6em;
            line-height: 1em;
            align-items: center;
            color: $gray;
            font-weight: 400;
            @include breakpointMax($two-column-max) {
                margin-top: 1.5rem;
            }
            > div > span{
                display: inline-block;
                margin: .35em 1em .35em 0;
            }
        }
        .left-side{
            flex-grow: 100;
        }
        .right-side span:last-child{
            margin-right: 0;
        }
        @include breakpointMax($one-column-max) {
            .badge-text{
                display: none;
            }
        }
        .icon{
            vertical-align: bottom;
            fill: $black;
            text-align: center;
            width: 1.5em;
            margin-right: .125em;
            text-align: center;
        }
    }
    .source-container{
        padding: 1rem 0;
        @include breakpointMax($two-column-max) {
            border-bottom: $border-style;
        }
        @include breakpointMin($two-column-max + 1) {
            padding-left: $two-and-three-column-padding;
        }
        @include breakpointMin($two-column-max-large + 1) {
            padding-left: $three-column-large-padding;
        }
        .flexible-container{
            width: 100%;
        }
        .source-bar, .speaker-bar {
            min-height: 5.6rem;
            margin-bottom: 1rem;
            &, > a{
                width: 100%;
                display: inline-block;
            }
            img{
                width: 5.6rem;
            }
            img, .text{
                float: left;
            }
            &.speaker-bar .text{
                padding-top: 1rem;
            }
            .text, .icons{
                width: calc(100% - 5.6rem);
                padding-left: 1rem;
            }
            .text{
                padding-top: .1rem;
                label{
                    font-size: 1.3rem;
                    text-transform: uppercase;
                }
                h3{
                    font-size: 1.6rem;
                    margin: .2rem 0 0 0;
                    font-weight: 600;
                }
            }
            .icons{
                font-size: 1.8rem;
                float: right;
                height: 1em;
                margin-top: .25em;
                line-height: .8em;
                .icon{
                    fill: $light-gray;
                    height: 1em;
                }
                a{
                    height: 1em;
                    width: 1em;
                    float: left;
                    margin-right: .65em;
                    cursor: pointer;
                    &:hover .icon{
                        fill: $gray;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
                .alert{
                    position: relative;
                    margin-right: .75em;
                }
                .indicator{
                    background: rgba($error-color, .9);
                    color: white;
                    top: 0;
                    right: -.75em;
                    border-radius: 50%;
                    height: 1.35em;
                    width: 1.35em;
                    text-align: center;
                    line-height: 1.35em;
                    font-size: .6em;
                    position: absolute;
                }
            }
        }

        @include breakpointMinMax($one-column-max + 1, $two-column-max) {
            .flexible-container{
                display: flex;
                flex-wrap: wrap;
            }
            .source-bar, .speaker-bar {
                width: auto;
                position: relative;
                &:first-child{
                    margin-right: 2em;
                }
                &:last-child{
                    flex-grow: 100;
                }
                .icons{
                    background: $primary-bg-color;
                    width: auto;
                    position: absolute;
                    top: .45em;
                    right: 0;
                    margin: 0;
                    padding: 0;
                }
            }
            .source-bar{
                img, .text{
                    margin-top: .9em;
                }
            }
        }
    }
    .sidebar{
        font-size: 1.8rem;
        padding-bottom: 1.5em;
        @include breakpointMax($one-column-max) {
            padding-bottom: $one-column-padding;
            border-bottom: $border-style;
        }
        h5{
            text-transform: uppercase;
            margin: 0 1.15em .25em;
            @include breakpointMax($one-column-max) {
                margin: 0 .7em .25em;
            }
        }
        ul{
            padding: 1em 0 0;
            margin: 0;
            @include breakpointMax($one-column-max) {
                padding: $one-column-padding 0 0;
            }
            li{
                cursor: pointer;
                a{
                    color: $dark-gray;
                    width: 100%;
                    display: inline-block;
                    padding: .4em 1em;
                    @include breakpointMax($one-column-max) {
                        padding: .4em $one-column-padding;
                    }
                    &:hover{
                        background: $secondary-bg-color;
                    }
                }
                .item-icon{
                    margin: 0 .5em 0 0;
                    width: 1.2em;
                    text-align: center;
                    display: inline-block;
                }
                .caret{
                    margin-left: .3em;
                    fill: $dark-gray;
                }
            }
        }
        .facebook-like{
            padding: .5em 1em;
            margin-bottom: 1em;
        }
    }
    .body-container{
        font-size: 1.6rem;
        line-height: 1.6em;
        padding-bottom: 5rem;
        .metadata-secondary{
            margin: 1em 0;
            position: relative;
            .data-set{
                width: 100%;
                line-height: 1.8em;
            }
            label{
                display: inline-block;
                text-transform: uppercase;
                color: $gray;
                position: absolute;
            }
            span{
                margin-left: 7em;
                display: inline-block;
                a[href^=tel] {
                    color: inherit;
                    text-decoration: none;
                    font-weight: inherit;
                }
            }
        }
        .description{
            border-bottom: $border-style;
            padding-bottom: 1em;
            .text{
                margin: 1em 0 .5em;
            }
        }
    }
}
