.centerMarker{
    font-size: 1em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.search-box, .church-list, .leaflet-bar, .leaflet-touch .leaflet-bar, .leaflet-popup-content-wrapper,  .leaflet-popup-tip,.left-container_close-button, .group-dropdown, .help-background{
    box-shadow: $box-shadow;
}

.search-box, .church-list, .leaflet-bar a, .leaflet-popup-content-wrapper, .leaflet-popup-tip, .autocomplete-results, .left-container_close-button, main .search-box form button, .group-dropdown ul, #search_form, .help-background{
    background-color: $primary-bg-color;
    border-radius: 0 !important;
}

.finder main{
    overflow: hidden;
    padding-top: 0;
    position: absolute;
    top: $header-height;
    height: calc(100% - #{$header-height});
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 0;

    #map {
        top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .search-box{
        width: $search-box-width;
        max-height: 100%;
        max-width: 100%;
        z-index: 10000;
        pointer-events: auto;
        form{
            height: 100%;
            position: relative;
            display: block !important;
        }
        input, button{
            border: none;
            font-size: 1em;
            height: $search-box-height;
        }
        button {
            width: $search-box-height;
            margin: 0;
            .icon{
                fill: $lightest-gray;
                font-size: 1.7rem;
                margin-top: .25rem;
            }
        }
        .entered + button .icon{
            fill: $primary-accent-color;
        }
        input[type="search"]{
            padding: 0;
            margin: 0 0.75rem;
            font-size: 1.4rem;
            width: calc(100% - 4rem - 1.5rem);
            float: left;
            font-weight: 300;
            @media screen and (max-width: $mobile-width) {
                font-size: 1.2rem;
            }
        }
        input[type="search"].entered:focus + button{
            color: $primary-accent-color;
            cursor: pointer;
        }
    }
    .group-dropdown{
        width: $search-box-width;
        max-width: 100%;
        pointer-events: auto;
        max-height: 320px;
        height: $dropdown-box-height;
        position: static;
        z-index: 1000000;
        .selected-box{
            background-color: $off-white;
            height: $dropdown-box-height;
            cursor: pointer;
            font-size: 1.1rem;
            outline-offset: -.2rem;
            &:hover{
                background-color: $lightest-gray;
            }
            div{
                display: inline-block;
                float: left;
                margin-left: 1rem;
                line-height: 3rem;
                height: 100%;
                color: $dark-gray;
            }
            .selected-label{
                color: $gray;
                text-transform: uppercase;
            }
            .dropdown_close-button, .dropdown_caret{
                float: right;
                margin-left: 0;
                margin-right: 0.5rem;
                font-size: 1.5rem;
                width: 2.5rem;
                line-height: $dropdown-box-height - .3rem;
                text-align: center;
                .icon{
                    fill: $dark-gray;
                }
            }
            .dropdown_close-button{
                display: none;
                .icon{
                    fill: $primary-accent-color;
                    font-size: 1.5rem;
                }
                &:hover .icon{
                    fill: $primary-accent-color-highlight;
                }
            }
        }
        ul{
            z-index: 1;
            height: calc(100% - #{$dropdown-box-height});
            overflow: auto;
            &, li{
                background: $primary-bg-color;
            }
            li{
                padding: 1rem 1rem 1rem 2.5rem;
                font-size: 1.1rem;
                cursor: pointer;
                border-bottom: 1px solid $lightest-gray;
                outline-offset: -.2rem;
                &.active, &.outline{
                    background-color: $off-white;
                }
                &.active.outline{
                    background-color: $lightest-gray;
                }
            }
        }
        &.open{
            position: relative;
            height: calc(100% - #{$search-box-height});
            .selected-box{
                background-color: $lightest-gray;
                border-bottom: 1px solid $off-white;
                border-bottom-color: $light-gray;
                outline-offset: -.2rem;
                .dropdown_caret .icon{
                    fill: $darkest-gray;
                }
            }
        }
        &.active{
            .selected-title{
                color: $primary-accent-color;
            }
            .selected-box{
                .dropdown_close-button{
                    display: block;
                }
                .dropdown_caret{
                    display: none;
                }
            }
        }
    }
    .autocomplete-results{
        border-top: 1px solid $lightest-gray;
        max-height: 30rem;
        position: relative;
        pointer-events: auto;
        z-index: 10000;
        ul{
            a{
                display: block;
                text-decoration: none;
                pointer-events: auto;
                &.active{
                    background-color: rgba($lightest-gray, 0.5);
                }
            }
            li{
                padding: 1rem;
                font-size: 1.2rem;
                color: $darkest-gray;
                border-bottom: 1px solid $lightest-gray;
                font-weight: 300;
                .icon{
                    font-size: 1.5rem;
                    float: left;
                    fill: $light-gray;
                    color: $light-gray;
                    text-align: center;
                    width: 1.6rem;
                    &.globe{
                        margin-top: -.1rem;
                    }
                }
                .detail-container{
                    padding-left: 2.5rem;
                    .church-address{
                        font-style: italic;
                        line-height: 1.45rem;
                        color: $gray;
                        font-weight: 300;
                    }
                }
                .ac-title{
                    @extend .overflow-ellipsis;
                    min-height: 1.4rem;
                    display: block;
                }
            }
        }
    }
    .church-list{
        position: absolute;
        height: calc(100% - #{$search-box-height} - #{$dropdown-box-height} - #{$search-box-margin});
        max-height: 70%;
        top: calc(#{$search-box-height} + #{$dropdown-box-height} + #{$search-box-margin});
        width: 100%;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s;
        .dark-cover{
            transition: opacity 0.5s;
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $darkest-gray;
            z-index: 1;
            pointer-events: none;
        }

        ul{
            a{
                display: block;
                text-decoration: none;
                outline-offset: -.2rem;
                &:hover{
                    background-color: rgba($lightest-gray, 0.5);
                }
            }
            img{
                @media screen and (max-width: $mobile-width) {
                    display: none;
                }
                width: 4.5rem;
                float: left;
            }
            li{
                line-height: normal;
                padding: 1rem;
                font-size: 1.1rem;
                @media screen and (max-width: $mobile-width) {
                    font-size: 1.2rem;
                }
                overflow: auto;
                color: $darkest-gray;
                border-bottom: 1px solid $lightest-gray;
                font-weight: normal;
                .detail-container{
                    margin-left: 5.5rem;
                    @media screen and (max-width: $mobile-width) {
                        margin: 0;
                    }
                    .church-header{
                        .church-distance{
                            float: right;
                            color: $gray;
                        }
                        .church-title{
                            text-transform: uppercase;
                        }
                    }
                    .church-address, .church-group, .church-speaker{
                        font-style: italic;
                        line-height: 1.45rem;
                        color: $gray;
                        font-weight: 300;
                    }
                }
                .ac-title{
                    @extend .overflow-ellipsis;
                    display: block;
                }
            }
        }
        &.active{
            opacity: 1;
            pointer-events: auto;
        }
        &.fade.active .dark-cover{
            opacity: 0.75;
            pointer-events: none;
        }
    }
}








.sa-contents-container, .sa-player-container{
  width: 100% !important;
}
.sa_wave_container{
  margin-top: 0 !important;
}

.controls-container{
  top: auto !important;
  bottom: 0;
  left: calc(50% - 85px);
  .controls-ui{
    width: 3em;
  }
}
#sa_player_outer-container.full{
  max-width: 720px;
  margin: 0 auto;
  .sa-player-container{
    height: 100px !important;
    padding: 0 1em !important;
  }
}
