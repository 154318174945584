.embed-editor{
    $mobile: 760px;
    $editor-width-b1: 48rem;
    $editor-width-b2: 38rem;
    main{
        position: absolute;
    }
    .editor, .preview{
        position: absolute;
        top: $header-height;
        bottom: 0;
        @include breakpointMax($mobile){
            width: 100% !important;
            position: relative !important;
            top: 0 !important;
        }
    }
    &.popout main{
        .editor, .preview{
            top: 0 !important;
        }
    }
    .editor{
        $results-height: 14rem;
        $tab-nav-height: 4.4rem;
        left: 0;
        width: $editor-width-b1;
        overflow: auto;
        background-color: white;
        @include breakpointMax($desktop-small){
            width: $editor-width-b2;
        }
        .section{
            width: 100%;
            padding: 0 3rem;
            @include breakpointMax($mobile-width){
                padding: 0 2rem;
            }
        }
        .options{
            padding: 2.5rem 0;
            position: absolute;
            left:0 ; right: 0;
            bottom: $results-height;
            top: $tab-nav-height;
            @include breakpointMax($mobile){
                position: relative;
                top: 0; bottom: 0;
            }
        }
        .embed-results{
            padding: 3rem;
            border-top: $generic-border;
            height: $results-height;
            position: absolute;
            bottom: 0;
            @include breakpointMax($desktop-small){
                padding: 2.5rem;
            }
            @include breakpointMax($mobile-width){
                padding: 2.5rem 2rem;
            }
            @include breakpointMax($mobile){
                bottom: 0;
                position: relative;
            }
            p{
                color: $gray;
            }
        }
        em{
            font-size: 1.3rem;
            margin-bottom: 2rem;
        }
    }
    .preview{
        background: $secondary-bg-color;
        padding: 1.5rem 3rem;
        right: 0;
        width: calc(100% - #{$editor-width-b1});
        @include breakpointMin($mobile + 1){
            overflow-y: scroll;
        }
        @include breakpointMax($desktop-small){
            width: calc(100% - #{$editor-width-b2});
        }
        @include breakpointMax($mobile-width){
            padding: 1.5rem 2rem;
        }
        #preview{
            display: flex;
            z-index: 1;
            position: relative;
            min-height: 15rem;
            iframe{
                margin: auto;
                background: $secondary-bg-color !important;
            }
        }
        .sk-wave-container{
            position: absolute;
            left: 0; right: 0;
            top: 12rem;
        }

        .header{
            margin: 1rem 0 4rem;
            border-bottom-color: $dark-gray;
            h3{
                color: $dark-gray;
                margin-left: 0;
                margin-bottom: 1rem;
            }
        }
    }
    .splash{
        text-align: center;
        font-size: 2em;
    }
}

.embed-editor main{
    background-color: $secondary-bg-color;

    h2{
        font-weight: normal;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: $gray;
    }
    p{
        font-size: 1.4rem;
        margin: 0 0 1rem 0;
    }
    .dropdown{
        margin: 0 0 1rem 0;
        @include dropdown(1.4rem, 100%, 2.6em);
        &.disabled{
            &, select{
                background-color: $off-white;
                color: $gray;
            }
            border-color: $light-gray;
        }
    }

    .custom-sizes{
        display: inline-block;
        width: 100%;
    }
    #video-custom-size{
        margin-right: 0;
        label{
            margin-right: 1rem;
        }
    }
    $indent-width: 2.4rem;
    .indent{
        width: $indent-width;
        font-size: 2em;
        color: $dark-gray;
        padding-left: .2rem;
        display: none;
        &.show{
            display: inline-block;
        }
    }
    .dropdown.filters{
        width: calc(100% - #{$indent-width});
    }
    .size-input {
        display: none;
        margin-top: -.6em !important;
        .cross{
            margin: 0 .5rem;
        }
        .indent{
            display: inline-block;
        }
        &.visible{
            display: inline-block;
        }
    }
    .description{
        display: none !important;
        margin: .75rem 0;
        line-height: 1.8em;
        .input-with-label{
            margin-left: 0;
            input{
                background: $lightest-gray;
                color: $black;
            }
        }
        em{
            display: block !important;
            margin-bottom: 1em;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    &.browser .description.visible{
        display: inline-block !important;
    }

    #browser-custom-size.size-input{
        input{
            display: none;
            &.visible{
                display: inline-block;
            }
        }
    }
    .section {
        > label, input[type="checkbox"], .content-input, h2, em{
            display: none;
        }
        label{
            font-size: 1.4rem;
        }
        input + label{
            width: 50%;
            float: left;
            margin: 0 0 1em 0;
            padding-right: .5rem;
        }
        input, .size-input span{
            line-height: 3rem;
            width: 6rem;
            text-align: center;
            padding: 0;
            font-size: 1.3rem;
            height: 3.2rem;
        }
    }

    .input-pair{
        white-space: nowrap;
        float: right;
    }


    .input-with-label{
        border: 1px solid transparent;
        margin: 0 2rem .6rem 1rem;
        min-width: 22.5rem;
        label{
            margin: .8rem 1.5rem 0 0;
            display: inline-block;
            text-transform: uppercase;
            font-size: 1.4rem;
        }
    }

    .embed-headers > div{
        color: $dark-gray;
        display: none;
        h3{
            font-size: 1.8rem;
            margin: 0;
        }
        p{
            margin-top: .5em;
        }
    }

    &.browser .show-when-browser,
    &.video   .show-when-video,
    &.audio   .show-when-audio,
    &.webcast .show-when-webcast {
        display: inline-block;
    }
    &.browser h2.show-when-browser{
        display: inline-block;
        width: 100%;
    }

    &.video, &.audio, &.webcast{
        #browser-custom-size{
            display: none;
        }
    }

    &.browser .show-when-browser.align-with-classic, &.browser .show-when-browser.align-with-compact{
        display: none;
    }

    &.classic .show-when-browser.align-with-classic, &.compact .show-when-browser.align-with-compact{
        display: inline-block;
    }

    .embed-thumb {
        font-size: 1rem;
        margin: 1em 0;
    }
    [type="radio"]{
        &:not(:checked) + label{
            .embed-thumb{
                opacity: .8;
            }
            &:hover{
                .embed-thumb{
                    opacity: 1;
                }
            }
        }
        &:checked + label{
            cursor: default;
        }
    }

    #customTimeCheckbox{
        &:checked ~ .time-slider{
            height: 9.5rem;
            padding: 2.5rem 0;
        }
        &:not(:checked) ~ .time-slider, &:disabled ~ .time-slider{
            height: 0 !important;
            padding: 0 !important;
        }
    }
    .webcastButtonOptions{
        display: none;
    }
    &.webcast #webcastButtonCheckbox:checked ~ .webcastButtonOptions{
        display: block;
    }
    &.webcast, &.browser{
        #customTimeCheckbox ~ .time-slider{
            height: 0 !important;
            padding: 0 !important;
        }
    }

    .time-slider{
        overflow: hidden;
        transition: 0$generic-transition-speed;
        h2{
            margin: 2rem 0 1.5rem;
        }
    }
    .webcastButtonOptions{
        .dropdown{
            margin-bottom: 1.25rem;
        }
        label{
            margin-bottom: .75rem;
        }
        em{
            display: block;
        }
    }
    .section{
        display: block;
        margin-bottom: 1rem;
        .browser-sermons{
            display: block;
        }
        &.dropdown-container{
            display: inline-block;
        }
        &.custom-time{
            display: none;
        }
    }
}
