.finder{
    .modal-header{
        overflow: hidden;
        float: left;
        width: calc(100% - 18rem);
        .broadcaster-image{
            display: inline-block;
            float: left;
            height: 12.5rem;
            img{
                width: 12.5rem;
            }
            @media screen and (max-width: $mobile-width) {
                width: 100%;
                margin: 0 auto 2rem;
                text-align: center;
            }
        }
        .broadcaster-header-info{
            margin-left: 12.5rem;
            padding: 0 2rem;
            @media screen and (max-width: $mobile-width) {
                padding: 0;
                margin: 0;
                float: left;
            }
            .broadcaster-title{
                border-bottom: 2px solid $lightest-gray;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                @extend .overflow-ellipsis;
                color: $gray;
                padding-bottom: .1rem;
                span{
                    margin-left: 1rem;
                }
                .broadcaster-name{
                    color: $darkest-gray;
                }
                .divider{
                    border-left: 2px solid $lightest-gray;
                    // display: inline-block;
                    height: 100%;
                }
                .broadcast-speaker{
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    margin-left: 1.25rem;
                }
            }
            .broadcaster-description{
                max-height: 14rem;
                min-height: 9.5rem;
                padding-right: 1rem;
                line-height: 1.4rem;
                font-size: 1.2rem;
                margin-top: 0;
                @media screen and (max-width: $mobile-width) {
                    min-height: 0;
                    padding-right: 0;
                    max-height: none;
                }
            }
        }
    }
    .modal-sermon{
        float: left;
        width: calc(100% - 18rem - 2rem);
        padding: 1rem 3rem 0;
        .sermon-details{
            font-size: 1.2em;
            color: $gray;
            text-align: center;
            margin-bottom: 1.5rem;
            line-height: 1.25em;
            .sermon-title{
                margin: 0 0 .5em 0;
                font-size: 1.3em;
            }
            .sermon-subtitle{
                text-transform: uppercase;
            }
        }
    }
    .modal-details{
        padding: 1rem;
        float: right;
        background-color: $lightest-gray;
        color: $darkest-gray;
        width: 18rem;
        height: 100%;
        position: relative;
        a{
            color: $primary-accent-color;
            .icon{
                fill: $primary-accent-color;
            }
            &:hover{
                color: $primary-accent-color-highlight;
                .icon{
                    fill: $primary-accent-color-highlight;
                }
            }
        }
        .category{
            margin-bottom: .8rem;
            line-height: 1.4;
            &:last-child{
                margin-bottom: 0;
            }
            .category-title{
                font-weight: 600;
                font-size: 1.15em;
            }
            .category-details{
                word-break: break-word;
                em{
                    margin-bottom: 0.5rem;
                    display: block;
                }
            }
            .address-link{
                text-decoration: underline;
                font-weight: 600;
            }
            &.social{
                border-top: .1rem solid $dark-gray;
                padding-top: .5rem;
                margin: 1rem 0;
                font-size: 1.4rem;
                overflow: hidden; g
                a{
                    display: inline-block;
                    width: 2rem;
                    text-align: center;
                }
            }
            &.vacant{
              background-color: $secondary-bg-color;
              padding: 0.5rem;
              font-size: 1.2rem;
              text-align: center;
              text-transform: uppercase;
            }
            &.webcast{
                text-transform: uppercase;
                font-weight: normal;
                text-decoration: none !important;
                position: relative;
                font-size: 1.1rem;
                display: block;
                color: $live-color;
                .icon{
                    fill: $live-color;
                }
                &:hover{
                    color: $live-color-light;
                    .icon{
                        fill: $live-color-light;
                    }
                }
                > span{
                    position: absolute;
                    top: .4rem;
                }
                .icon{
                    margin-right: .75rem;
                    font-size: 1.5rem;
                }
                img{
                  width: 100%;
                  display: block;
                }
                div{
                    position: absolute;
                    left: 0;
                    right: 0;
                    color: white;
                    &.play-icon{
                        font-size: 2.5em;
                        top: 2rem;
                        text-align: center;
                        text-shadow: 0 0 2.5rem black;
                        .icon{
                            fill: white;
                        }
                    }
                    &.overlay{
                        bottom: 0;
                        padding: .5rem;
                        padding-right: 0;
                        background: rgba(black,.5);
                        font-weight: 600;
                        span{
                            color: red;
                            font-family: arial, sans-serif;
                            font-size: 2em;
                            line-height: 0;
                            vertical-align: sub;
                            display: inline-block;
                            margin: 0 .25rem;
                        }
                    }
                }
                &:hover{
                    img{
                        opacity: .8;
                    }
                    div.overlay{
                        background-color: rgba(black, .4);
                    }
                }
            }
        }
    }
    @media screen and (max-width: $modal-stack-width) {
        .modal-header, .modal-sermon, .modal-details{
            float: none;
        }
        .modal-header{
            width: 100%;
            margin-bottom: 2rem;
        }
        .modal-details{
            width: 100%;
            height: auto;
            padding-right: 0;
            margin-bottom: 2rem;
            .category{
                float: left;
                margin-right: 1rem;
                width: calc(100% / 3 - 1rem);
            }
        }
        .modal-sermon{
            width: 100%;
            padding: 2rem 0 ;
            .sermon-title, .sermon-title a{
                font-size: 2rem;
            }
            .sermon-details{
                font-size: 1rem;
            }
        }
    }
    @media screen and (max-width: $mobile-width) {
        .modal-details{
            .category{
                width: calc(100% / 2 - 1rem);
                margin-bottom: 1rem;
            }
        }
        .modal-header{
            .broadcaster-header-info{
                .broadcaster-title{
                    span{
                        margin-left: 0.5rem;
                    }
                    font-size: 1.25rem;
                    .broadcast-speaker{
                        font-size: 1rem;
                        margin-left: 0.75rem;
                    }
                }
            }
        }
    }
}
