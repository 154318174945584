.admin-broadcaster-toolbar{
    position: fixed;
    bottom: 0;
    right: 2em;
    z-index: 9999999999999999;
    border: $generic-border;
    border-bottom: none;
    font-size: 1rem;
    &:hover{
        border-color: $primary-accent-color;
    }
    button{
        font-size: 1rem;
        text-transform: capitalize;
        &:not(.success){
            transition: color $generic-transition-speed;
        }
        &.success{
            color: $success-color;
        }
    }
    .accordion-button, .accordion-container{
        background: $primary-bg-color;
    }
    .accordion-button{
        justify-content: space-between;
        cursor: pointer;
        padding: 1em;
        display: flex;
        span{
            margin: 0 .5em;
            width: 100%;
        }
        &.closed{
            &:not(:hover){
                opacity: .5;
                transition: opacity $generic-transition-speed;
            }
        }
    }
    .admin-toolbar-switch-broadcaster{
        &.filled{
            input{
                width: 0;
            }
        }
        input{
            border-bottom: $generic-border;
            margin-right: .25em;
        }
        &.active input{
            border-bottom-color: $primary-accent-color;
        }
        &.error input{
            border-bottom-color: $error-color;
        }
        &:not(.filled){
            span:not(.spinner){
                width: 0;
                overflow: hidden;
                display: inline-block;
            }
            .icon{
                display: inline-block;
                pointer-events: all;
            }
        }
        &:not(.active):not(.error){
            .spinner{
                opacity: 1;
                margin-left: 0;
            }
            .icon{
                display: none !important;
            }
        }
        &.active, &.error{
            .spinner{
                display: none;
            }
        }
    }
    .admin-broadcaster-toolbar-contents{
        padding-top: 0;
    }
    .hr{
        margin-bottom: 1em;
    }
    .error.filled{
        background-color: $error-color;
        &:hover{
            background-color: $error-color-dark;
        }
    }
    .error:not(.filled){
        color: $error-color;
        &:hover{
            color: $error-color-dark;
        }
    }
    .admin-toolbar-broadcaster-id{
        pointer-events: none;
        font-style: italic;
        opacity: .5;
        margin-left: .25em;
    }
    input{
        border: 0;
        height: 1em;
        padding: 0;
        background-color: transparent;
    }
}
