.four-o-four{
    main{
        font-size: 1.6em;
        text-align: left;
        .primary-content{
            max-width: 48rem;
        }
        a{
            color: $primary-accent-color;
            &:hover{
                color: $primary-accent-color-highlight;
                text-decoration: underline;
            }
        }
        .modal ul{
            padding-left: 2rem;
        }
    }
    .header{
        display: flex;
        align-items: center;
        font-size: 1.6em;
        margin: .25em 0 1em 0;
        overflow: visible;
        .icon{
            margin-right: 1rem;
        }
    }
    h1{
        font-weight: 400;
        margin: 0;
    }
}
