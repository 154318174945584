body.report-feedback main{
    font-size: 1.3em;
    input, textarea{
        width: 100%;
        margin-bottom: 1rem;
        &:disabled{
            color: $dark-gray;
        }
    }
    form{
        display: block !important;
    }
    input{
        font-style: italic;
    }
    .block{
        max-width: 40rem;
        margin: 0 auto;
        & > p{
            line-height: 1.4;
        }
    }
    .feedback p{
        margin-top: 0 !important;
    }
    input[type=submit]{
        background: $gray;
        color: white;
        border: none !important;
        font-style: normal;
        &:focus{
            background: $primary-accent-color;
        }
        &:hover{
            background: $light-gray;
            color: white;
            &:focus{
                background: $primary-accent-color-highlight;
            }
        }
        &:disabled{
            background: $light-gray;
        }
    }
}
