.finder {
    .help-button{
        cursor: pointer;
        font-size: 1.8rem;
        font-weight: 600;
        color: $darkest-gray !important;
    }

    .help-background{
        color: $dark-gray;
        padding: 2rem;
        font-size: 1.2rem;
        position: absolute;
        z-index: 1100;
        right: 0;
        opacity: 0;
        pointer-events: none;
        a{
            text-decoration: underline;
        }
        h4{
            font-weight: 600;
            margin-top: 0;
            text-transform: uppercase;
            margin-bottom: .25rem;
            color: $light-gray;
        }
        ul{
            margin: 0 0 1.25rem 1rem;
            font-weight: 300;
            line-height: 1.5;
            &:last-child{
                margin-bottom: 0;
            }
            span{
                font-weight: normal;
                color: $darkest-gray;
            }
        }
    }

    &.help .help-background{
        opacity: 1;
        pointer-events: auto;
        @include fadeInDown(
            $duration: 0.75s
        );
    }
    &.help-closed .help-background{
        @include fadeOutUp(
            $duration: 0.25s
        );
    }
}
