// $mobile-width: 480px;
// $modal-stack-width: 680px;
// $desktop-small: 960px;
// $desktop-medium: 1280px;
// $desktop-large: 1440px;

$form-banner-height: 5.5rem;
$broadcaster-header-height: 5rem;
$header-and-banner-height: $form-banner-height + $broadcaster-header-height;

.broadcaster-pages{
    .modal-container{
        top: 0;
    }
    .sk-wave-container{
        width: calc(100% - 8rem);
        margin-top: 8rem;
    }
    .dynamics-container{
        padding-right: 10%;
        margin-right: -1rem;
        font-size: 1.6rem;

        @extend .scrolls;
        @extend .markdown;
        
        *:first-child{
            margin-top: 0 !important;
        }
    }

    .modal-contents-container{
        background-color: $primary-bg-color;
    }

    header.custom{
        color: white;
        box-shadow: none;
        flex-wrap: wrap;
        height: auto;
        position: relative;
        background-color: $primary-accent-color-dark;

        .translation-dropdown-button{
            color: rgba($primary-bg-color, .75);
            padding-right: 0;
            &.active{
                color: $primary-bg-color;
            }
            > .translation-icon{
                background-color: $primary-bg-color;
                color: $primary-accent-color;
            }
            .translation-dropdown{
                top: 3.3rem;
            }
        }

        a.header-logo{
            color: inherit;
        }
        .header-bg{
            position: absolute;
            top: 0; right: 0;
            left: 0; bottom: 0;
            background-color: $primary-accent-color-dark;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top left;
            background-image: url('../../sa_site/img/broadcaster-signup-overview-header-bg.jpg');
        }
        button{
            &:not(.active){
                color: white;
                &:hover{
                    background-color: rgba(white, .1);
                }
            }
            &.active{
                background: white;
                border-color: white;
                &:hover{
                    background-color: rgba(white, .9);
                    color: $primary-accent-color;
                }
            }
        }
        .right-flex, .left-flex{
            align-items: center;
            margin-top: .5rem;
            z-index: 3;
        }
        .left-flex a{
            color: white;
            font-size: 1.4rem;
            &:hover{
                text-decoration: underline;
            }
        }
        .right-flex{
            padding-right: 1.5em;
            .accent-link{
                color: white;
                font-size: 1.4em;
                margin-right: $site-button-spacing;
            }
            &:hover{
                text-decoration: underline;
            }
            a + a{
                margin-left: $site-button-spacing;
            }
        }
    }
    .desktop header.custom a.header-logo:hover{
        opacity: .9;
    }
    .hero{
        display: flex;
        width: 100%;
        font-size: 1.4em;
        z-index: 2;
        .hero-columns{
            @include breakpointMin($modal-stack-width){
                display: flex;
                justify-content: space-between;
                .description{
                    width: 65%;
                }
                h1{
                    width: 35%;
                }
            }
        }
        .centered-content{
            margin: auto;
            max-width: 96rem;
            padding: 1rem 1rem 2rem 1rem;
            @include breakpointMin($modal-stack-width){
                padding: 4rem;
            }
        }
        .description{
            font-size: 1.2em;
            p{
                margin: 0 0 1.5rem 0;
            }
        }
        h1{
            font-size: 2.5em;
            margin: 0 .5em 0 0;
            min-width: 20rem;
        }
        @include breakpointMax($modal-stack-width){
            padding: 1.5em;
            .centered-content{
                flex-wrap: wrap;
            }
            .description, h1{
                width: 100%;
            }
            h1{
                margin: 0 0 1.5rem 0;
            }
        }
    }

    main{
        padding: 0;
    }
    header.custom, .primary-content{
        p{
            line-height: 1.3em;
        }
        .condensed{
            font-family: 'Open Sans Condensed', sans-serif;
            font-weight: 300;
        }
        h2, .icon-container, a, h4{
            color: $primary-accent-color;
        }
        a:hover{
            color: $primary-accent-color-highlight;
        }
        .icon-container{
            font-size: 6em;
        }
        h2, h4{
            font-size: 1.8em;
        }
        h3{
            font-size: 1.4em;
        }
        h4 {
            .large-format{
                width: 100%;
                display: inline-block;
                font-size: 2.2em;
                margin-left: -.1em;
                letter-spacing: -.04em;
                em{
                    font-style: normal;
                    font-size: .5em;
                }
            }
        }
    }

    &[lang='de'] h4 .large-format{
        font-size: 2em;
    }
    li{
        margin-bottom: .25em;
    }
    h5{
        font-size: 2em;
        font-weight: 300;
        margin: 0;
        letter-spacing: -.025em;
    }
    .primary-content{
        margin-top: $broadcaster-header-height;
        font-size: 1.4em;
    }
    .row{
        ul{
            padding-left: 2rem;
        }
        li{
            list-style-type: initial;
        }
        @include breakpointMax($mobile-width){
            ul{
                padding-left: 1.5em;
            }
            .flex-grid-row > div{
                padding: 0;
            }
        }
    }
    @include breakpointMax($modal-stack-width){
        .med-6-col + .med-6-col{
            h4{
                margin-top: 0;
            }
            margin-bottom: 2em;
        }
    }
}

.broadcaster-id-input{
    label{
        margin: .5rem .5rem 0 0;
    }
    input{
        width: 100%;
    }
    .input-container{
        display: flex;
        align-items: center;
    }
    .validation-container{
        border-radius: 50%;
        color: white;
        flex-shrink: 0;
        text-align: center;
        line-height: 1.4em;
        margin-left: .5rem;
        display: none;
        height: 2.1rem;
        width: 2.1rem;
        .icon, .spinner{
            display: none;
        }
    }
    .message{
        margin-top: .5em;
        display: none;
    }
    &.valid .validation-container,
    &.invalid .validation-container,
    &.validating .validation-container,
    &.valid .check,
    &.validating .spinner,
    &.invalid .close{
        display: inline-block;
    }
    .spinner{
        opacity: 1;
        border-color: $primary-bg-color;
        border-width: .2em;
    }
    &.invalid{
        .validation-container{
            background-color: $error-color;
        }
        .message{
            color: $error-color;
            display: block;
        }
    }
    &.valid{
        .validation-container{
            background-color: $primary-accent-color;
        }
    }
    &.validating{
        .validation-container{
            background-color: $light-gray;
        }
    }
}

.broadcaster-articles-of-faith{
    h3{
        text-align: center;
        margin: 0 auto 2em;
    }
    .hero .centered-content{
        max-width: 84rem;
    }
    main{
        ol, ul{
            &:first-child{
                padding-left: 1.75em;
            }
        }
        li{
            margin-bottom: 1.25em;
        }
    }

}

.broadcaster-signup-form{
    header.custom{
        position: fixed;
    }
    header.custom, .primary-content{
        max-width: none;
    }
    .primary-content{
        padding: 0;
    }
    .footer-buttons{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5em;
    }
    .understated{
        color: $dark-gray;
    }
    [role="radio"]{
        margin: 0 0 1rem 0;
        font-size: 1em !important;
    }
    .notice{
        font-size: 1.4rem;
        p:last-child{
            margin-bottom: 0;
        }
        &.sale{
            background: $success-color-light;
            padding: 1rem;
            border-radius: .3rem;
        }
        &:not(.sale){
            color: $dark-gray;
            margin: 1rem 0;
        }
    }
    .sale-banner{
        height: $form-banner-height;
        background: $success-color-light;
        @include breakpointMin($modal-stack-width) {
            position: fixed;
        }
        font-weight: bold;
        width: 100%;
        box-shadow: $box-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $success-color-dark;
        z-index: 2;
        padding: 0rem 1.5rem;
    }
    @include breakpointMin($modal-stack-width) {
        &.has-sale{
            .header-container{
                height: calc(100vh - #{$header-and-banner-height});
                top: $header-and-banner-height;
            }
            .form-container{
                padding-top: $form-banner-height;
                min-height: calc(100vh - #{$header-and-banner-height} + .1rem);
            }
        }
        &:not(.has-sale){
            .header-container{
                height: calc(100vh - #{$broadcaster-header-height});
            }
            .form-container{
                min-height: calc(100vh - #{$broadcaster-header-height} + .1rem);
            }
        }
    }
    .header-container{
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $primary-accent-color-dark;
        background-image: url('../../sa_site/img/broadcaster-signup-form-header-bg.jpg');
        overflow: hidden;
        @include breakpointMin($modal-stack-width) {
            position: fixed;
        }
        .logo{
            position: absolute;
            bottom: .75em;
            right: .75em;
            z-index: 10;
            font-size: 3em;
            color: white;
            @include breakpointMax($modal-stack-width) {
                font-size: 2em;
    		}
            @include breakpointMax($modal-stack-width, true) {
                font-size: 2em;
    		}
            @include breakpointMax($mobile-width, true) {
                font-size: 1.5em;
                right: .65em;
                bottom: .65em;
    		}
            @include breakpointMax($mobile-width) {
                font-size: 1.5em;
    		}
        }
        h2{
            text-align: right;
            margin: 0 .25em .5em 0;
            font-weight: lighter;
            color: white;
            font-size: 8em;

            @include breakpointMax($modal-stack-width) {
                font-size: 5em;
                text-align: center;
                width: 100%;
                margin: 0;
    		}
            @include breakpointMax($modal-stack-width, true) {
                font-size: 5em;
    		}
            @include breakpointMax($mobile-width, true) {
                font-size: 3em;
    		}
            @include breakpointMax($mobile-width) {
                font-size: 3em;
    		}
        }
        &:before, &:after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 8em;
            @include breakpointMax($modal-stack-width) {
                height: 4em;
            }
        }
        &:after{
            background: linear-gradient(0deg, $primary-accent-color 0%, transparent 100%);
            bottom: 0;
        }
        &:before{
            content: '';
            background: linear-gradient(0deg, transparent 0%, $primary-accent-color 100%);
        }
        .text{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            top: 1em;
            height: 100%;
            padding: 5em 2em;
            @include breakpointMin($modal-stack-width) {
                width: 20em;
                right: 0;
                position: absolute;
                padding: 0;
                line-height: .9;
            }

            [lang='ko'] &{
                @include breakpointMin($modal-stack-width) {
                    writing-mode: vertical-rl;
                    top: -1em;
                }
            }
        }
    }
    .form-container{
        @include breakpointMin($modal-stack-width) {
            margin-left: 50%;
            > *{
                max-width: 44rem;
            }
        }
        form{
            padding: 1rem;
            font-size: 1.4em;
            .dropdown select{
                height: 3em;
            }
            > *{
                width: 100%;
            }
            input[type="checkbox"]{
                display: none;
            }
            [type="checkbox"] + label{
                line-height: 1.8em !important;
            }
        }
        p{
            font-size: .9em;
        }
        label{
            margin-bottom: .5rem;
        }
        .page-header, .column{
            padding: 0 1.5rem .5rem 1.5rem;
        }
    }
    .form-container, .header-container{
        @include breakpointMin($modal-stack-width) {
           width: 50%;
        }
    }
    .form-header{
        padding: 2.5rem;
        h3{
            font-size: 1.8em;
            margin-bottom: 0;
        }
        p{
            margin: 2rem 0 3rem;
        }
    }
    .page-header{
        font-size: .9em;
        margin-bottom: 0;
    }
    .continue .submit{
        display: none;
    }
    [type="checkbox"] + label{
        margin: 0 0 .5rem 0 !important;
    }
    .page{
        position: relative;
        margin: 0;
        transition: margin $generic-transition-speed;
    }
    .page.move-out{
        margin-left: -100%;
    }
    .page.move-in{
        margin-left: 100%;
    }
    .page:not(button):not(.active):not(.move-out):not(.move-in){
        display: none;
    }
    .page-1.active{
        ~ .column .footer-buttons{
            justify-content: flex-end;
            .back{
                display: none;
            }
        }
    }
    .page-6.active{
        ~ .column .footer-buttons{
            .next{
                display: none;
            }
            .submit{
                display: block;
            }
        }
    }
    .error-message{
        color: $error-color;
    }
    iframe#billing-card{
        margin-top: 2rem;
    }
    #billing-card ~ p{
        font-style: italic;
        color: $gray;
        margin-top: 1em;
        font-size: 1.4rem;
    }

}

.form-pager{
    justify-content: center;
    display: flex;
    > button{
        border: none;
        background: transparent !important;
        padding: .5em .35em;
        &:after{
            content: '';
            width: .5em;
            height: .5em;
            display: block;
            background-color: $lightest-gray;
            border-radius: 50%;
        }
        &.active{
            cursor: default !important;
            &:after{
                background-color: $primary-accent-color;
            }
        }
        &:hover:not(.active):after{
            background-color: $gray;
        }
    }
}

.broadcaster-signup-overview{
    .primary-content{
        padding: 0;
        max-width: none;
        margin-top: 0;
    }
    @include breakpointMin($modal-stack-width){
        .hero h1{
            font-size: 3em;
        }
    }
    .centered-content{
        max-width: 64rem;
    }
    .header-bg{
        background-image: url('../../sa_site/img/broadcaster-signup-overview-header-bg.jpg');
    }
    @include breakpointMax($modal-stack-width){
        ul{
            margin: 0;
        }
        .med-4-col + .med-4-col + .med-4-col{
            margin-top: -1rem;
        }
    }
    .modal-link{
        color: $primary-accent-color;
        cursor: pointer;
        &:hover{
            color: $primary-accent-color-highlight;
        }
    }
    .spinner-container{
        text-align: center;
        font-size: 2em;
        margin: 3em 0;
        opacity: .5;
    }
    .signup-fees-container{
        margin-bottom: 2em !important;
        @include breakpointMax(960px){
            width: 100%;
        }
        > div{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
        em{
            font-style: normal;
        }
        span{
            display: block;
        }
        text-align: center;
        .med-5-col{
            font-size: 1.2em;
        }
        .understated{
            font-size: .55em;
            color: $gray;
            margin-top: .5em;
        }
        .limited-time-offer{
            background: $sale-color;
            color: white;
            text-transform: uppercase;
            font-size: .8em;
            display: inline-block;
            padding: 0 1rem;
            margin-bottom: .5rem;
        }
        .med-2-col + .med-5-col h4{
            color: $black;
        }
        .plus{
            font-size: 2em;
        }
        h4{
            margin: .5em 0;
        }
    }
    .row{
        padding: 1em 2em;
        @include breakpointMin($modal-stack-width){
            padding: 2em 4em;
        }
        .med-12-col{
            margin-top: 1em;
        }
        .flex-grid-row{
            max-width: 96rem;
            display: flex;
            margin: auto;
            &:not(.no-wrap){
                flex-wrap: wrap;
            }
        }
        h3, h2{
            margin: .5em 0 .5em;
        }
        .icon-container{
            padding-bottom: 1rem;
        }
        &:nth-child(even){
            background: $secondary-bg-color;
        }
    }
    .slider .slick-arrow{
        background-color: $primary-bg-color !important;
        display: flex;
        align-items: center;
        font-size: 1.5em;
        color: $light-gray;
        cursor: pointer;
        &:hover{
            color: $dark-gray;
        }
    }
    .slide{
        padding: 0 3em;
    }
    .slick-initialized .slick-track {
        display: flex;
        align-items: center;
    }
    .quote-slide{
        max-width: 78rem;
        margin: auto;
        padding: 2em 0;
        .quote{
            font-size: 1.4em;
            line-height: 1.4em;
            @include breakpointMax($modal-stack-width){
                font-size: 1.2em;
                line-height: 1.3em;
            }
            .icon{
                color: $primary-accent-color;
            }
        }
        .metadata{
            text-align: center;
            margin-top: 2.5em;
            font-size: .9em;
            span:after{
                content: ", ";
            }
        }
    }
}
